<template>
  <fki-container
    fixed
    left="0"
    width="100%"
    column
    class="fki-page-careers"
  >
    <fki-container-header
      min-height="100px"
      :color="page.color"
      size="30px"
      :title="page.body"
    />
    <div
      v-if="currentJob"
      class="fki-career-wrapper"
      :class="page.color"
    >
      <div class="fki-job-container">
        <h3 class="fki-job-title">
          {{ currentJob.title }}
        </h3>
        <p class="fki-job-details">
          <span class="fki-job-id">Job ID: {{ currentJob.jobId }} </span>
          <span v-if="currentJob.department"> | </span>
          <span class="fki-job-department">{{ currentJob.department }}</span>
        </p>
        <h4
          style="color:#fff;font-size:14px; "
          class="fki-job-description-heading"
        >
          DESCRIPTION
        </h4>
        <div
          class="fki-job-description fki-job-list-container"
          v-html="currentJob.description"
        />
        <div
          v-if="currentJob.responsibilities.length"
          style="margin-top:20px;"
          class="fki-job-responsibilities fki-job-list-container"
        >
          <p
            style="color:#fff;font-size:13px;margin-bottom:0; "
            class="fki-job-requirements-heading"
          >
            Responsibilities:
          </p>
          <ul>
            <li
              v-for="item, index in currentJob.responsibilities"
              :key="index"
              v-html="item.responsibility"
            />
          </ul>
        </div>
        <h4
          v-if="currentJob.qualifications.length"
          style="color:#fff;font-size:14px; margin-top:35px; "
          class="fki-job-qualifications-heading"
        >
          QUALIFICATIONS
        </h4>
        <div
          v-if="currentJob.qualifications.length"
          class="fki-job-qualifications fki-job-list-container"
        >
          <ul>
            <li
              v-for="item, index in currentJob.qualifications"
              :key="index"
              v-html="item.qualification"
            />
          </ul>
        </div>
      </div>
      <div class="fki-button-container">
        <button
          :style="`background-color: ${page.color}`"
          class="apply"
          @click="openPopup()"
        >
          Apply Now
        </button>
        <button
          :style="`background-color: ${page.color}`"
          class="back"
          @click="goBack()"
        >
          Close
        </button>
      </div>
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    width="50%"
    @close="closeContact()"
  >
    <fki-contact-form-apply :current-job="currentJob" />
  </fki-container-popup>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import dayjs from 'dayjs'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'
import { FKIContactFormApply } from '@/components/FKIContact'

export default {
  name: 'Job',
  components: {
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-container-popup': FKIContainerPopup,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-form-apply': FKIContactFormApply
  },
  props: {
    jobId: {
      default: '',
      type: String
    }
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      page: { color: 'purple', body: 'Careers', slides: [] },
      currentJob: null,
      showContactPopup: false
    }
  },
  async created () {
    await this.getJob()
  },
  methods: {
    goBack () {
      this.$router
        .push({
          name: 'Careers'
        })
    },
    async getJob () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/job/' + this.jobId)
        this.currentJob = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    formatDate (date) {
      return dayjs(date).format('MMMM DD, YYYY')
    },
    openPopup () {
      this.showContactPopup = true
    },
    closeContact () {
      this.showContactPopup = false
    }
  }

}
</script>

<style lang="scss" scoped>
  .fki-page-careers{
    top:70px;
    bottom:0;
  }

.fki-button-container {
  width:100%;
  display: flex;
  justify-content: space-between;
}
button.back, button.apply {
    position: relative;
    margin-top: 0;
    width: 104px;
    font-size: 13px;
    clear: both;
    margin-top:24px;
    height: 42px;
    display: block;
    float:left;
    clear:none;
    border: none;
    float:left;
    outline: 0;
    line-height: 1;
    color: #fff;
    border: 0;
    padding: 10px;
    margin: 20px 0;
    transition: color .55s ease,  background-color .55s ease;
    cursor: pointer;
    &:hover{
      color:#000;
      background-color: #fff !important;
      transition: color .55s ease,  background-color .55s ease;
    }
  }
.fki-container-header {
   border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
.fki-career-wrapper {
  display: flex;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  margin: 0 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-items: flex-start;
  min-height: 1em;
  justify-content: flex-start;
  flex-direction: column;
  .fki-job-container {
    font-size: 13px;
    line-height: 21px;
      text-align: left;
    .fki-job-title {
      text-transform: uppercase;
      margin: 30px 0 5px;
      text-align: left;
      font-size: 24px;
        background-color:transparent !important;
    }
    .fki-job-details {
      color: #fff;
      font-size: 13px;
      line-height: 1;
      margin-bottom: 40px;
        background-color:transparent !important;
      p {
        margin: 0 0 10px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
          background-color:transparent !important;
      }
    }
    .fki-job-id {
      color: #fff;
    }
    ul li {
      font-size: 13px;
      line-height: 21px;
      background:transparent !important;
      font span {
          background:transparent !important;

      }
      span {
          background:transparent !important;
      }
       span span{
          background:transparent !important;
      }
    }
  }
  // button.apply {
  //   position: relative;
  //   margin-top: 0;
  //   width: 104px;
  //   font-size: 13px;
  //   height: 42px;
  //   float:right;
  //   display: block;
  //   //right: 24px;
  //  //bottom: 32px;
  //   border: none;
  //   outline: 0;
  //   line-height: 1;
  //   color: #fff;
  //   border: 0;
  //   padding: 10px;
  //   margin: 10px 0;
  //  transition: color .55s ease,  background-color .55s ease;
  //   cursor: pointer;
  //   &:hover{
  //     color:#000;
  //     background-color: #fff !important;
  //     transition: color .55s ease,  background-color .55s ease;
  //   }
  // }

}
@media screen and (min-width: 768px) {
  .fki-page-careers{
    top:131px;
    bottom:0;
  }
  .fki-career-wrapper {
      width: 55%;
  }
}
</style>
<style lang="scss" module>
  span[style]{
    font: unset !important;
    color: unset !important;
    background: unset !important;
  }
  font[style]{
    font: unset !important;
    color: unset !important;
    background: unset !important;
  }
</style>
